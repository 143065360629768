
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































#headPage {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.08);
  .headCenter {
    width: 1440px;

    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-tabs__nav-scroll,/deep/.el-tabs__nav-wrap{
      overflow: unset;
    }
    /deep/.el-tabs--top .el-tabs__item.is-top:last-child{
      transition: 0.3s;
      position: relative;
      color: #2878ff;
      &:before{
        content: '';
        position: absolute;
        top: 59px;
        right: -13px;
        width: 60px;
        height: 3px;
        background: #fff;
        z-index: 1;
      }
      &:after{
        content: '';
        position: absolute;
        top: 13px;
        right: -16px;
        width: 65px;
        height: 34px;
        border-radius: 17px;
        border: 1px solid #2878ff;
        background: transparent;
        transition: 0.3s;
        z-index: -1;
      }
      &:hover{
        color: #fff;
        &:after{
          background: #2878ff;
        }
      }
    }
    .headLeft {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #333;
      }
    }
    .headRight{
      height: 100%;
      padding-right: 18px;
      /deep/.el-tabs{
        height: 100%;

      }
      /deep/.el-tabs__nav-wrap{
        margin: 0;
        height: 100%;
      }
      /deep/.el-tabs__nav-scroll{
        height: 100%;
      }
      /deep/.el-tabs__nav{
        height: 100%;
      }
    }
  }
  img {
    cursor: pointer;
  }

}
@media (max-width: 1500px) {
  #headPage .headCenter{
    width: 1200px;
  }

}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}

/deep/.el-tabs__header{
  height: 100%;
  margin-bottom: 0;
}
/deep/.el-tabs__item{
  padding: 0 0 0 40px;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 62px;
}
/deep/.el-tabs__item:hover{
  color: #2878FF;
}
/deep/.el-tabs__nav-wrap::after{
  height: 0;
}
/deep/.el-tabs__item.is-active{
  color: #2878FF;
}

/deep/.el-tabs__active-bar{
  background-color: #2878FF;
  box-sizing: border-box;
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
  transition: unset;
}
.hideActiveBar{
  /deep/.el-tabs__active-bar{
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}






/deep/.el-dialog{
  border-radius: 8px;
}
/deep/.el-dialog__header{
  padding: 0 !important;
  height: 0;
}
/deep/.el-dialog__header .el-dialog__headerbtn .el-dialog__close{
  color: #666;
  transition: 0.3s;
  &:hover{
    color: #2878FF;
  }
}
/deep/.el-dialog__header .el-icon-close:before{
  border: 0!important;
}
/deep/.el-dialog__header .el-dialog__headerbtn{
  left: 14px;
  right: unset;
  top: 14px;
  bottom: unset;
  transform: translateY(0);
}
/deep/.el-dialog__body{
  padding: 44px 40px 40px;

}
.loginBox{
  /deep/.el-tabs__header{
    margin-bottom: 20px;
  }
  /deep/.el-tabs__nav-wrap::after{
    height: 0;
  }
  /deep/.el-tabs__active-bar{
    border-left: 16px solid #fff;
    border-right: 16px solid #fff;
    background-color: #2878FF;

  }

  /deep/.el-form-item{

    margin-bottom: 30px!important;
  }
  /deep/.el-tabs__item{
    font-weight: bold;
    font-size: 18px;
    color:#333;
  }
  /deep/.el-tabs__item.is-active{
    color: #2878FF;
  }
  .loginTextButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-button--text{
      padding: 0;
      color: #2878FF;
    }
  }
  .loginBottomButton{
    display: flex;
    margin-top: 15px;
    &>.el-button {
      width: 100%;
      height: 40px;
      background: #2878ff;
      border: #2878ff;
      color: #fff
    }
  }

}

//学员登录
//.login_input {
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//  .el-form-item {
//    margin-top: 0.5rem;
//    border-bottom: 1px solid #ccc;
//    display: flex;
//    align-items: center;
//    .loginput input {
//      border: none;
//    }
//    .forget {
//      height: 0.6875rem;
//      font-size: 0.875rem;
//      font-family: PingFangSC-Regular, PingFang SC;
//      font-weight: 400;
//      color: #409eff;
//      line-height: 0.6875rem;
//      cursor: pointer;
//      display: block;
//      flex-grow: 1;
//      text-align: end;
//    }
//  }

//  .el-form-item__content {
//    display: flex;
//    align-items: center;
//    flex: 1;
//  }
//}
.loginControl {
  //padding: 0 2.625rem;
  /deep/.el-button--text{
    color: #2878FF;
  }
  .login_box {
    .autoLogin {
      border-bottom: none;
      margin-bottom: 15px!important;
      line-height: unset;
      /deep/.el-form-item__content{
        line-height: unset;
      }
      .forget {
        width: 70px;
        font-size: 14px;
        font-weight: 400;
        color: #2878FF;
        cursor: pointer;
        display: block;
        flex-grow: 1;
        text-align: start;
      }
    }
    .loginputHasbtnBox{
      position: relative;
      /deep/.el-button--text{
        position: absolute;
        top: 0;
        right: 14px;
      }
      .yzmNum{
        position: absolute;
        top: 0;
        right: 38px;
      }
    }
    .loginputHasbtn{
      /deep/.el-input__inner{
        padding-right: 100px;
      }
      /deep/.el-input__icon{
        position: relative;
        right: 98px;
      }
    }
    //padding: 3.75rem 3.05rem;
    //background-color: #fff;
    //border-radius: 0.625rem;
    .switch {
      right: 0;
      top: 0;
      position: absolute;
      width: 78px;
      height: 78px;
      cursor: pointer;
    }
    //.divider {
    //  padding: 0 0.5rem;
    //}
    .loginButton {
      margin-bottom: 20px;
      text-decoration: none;

      /deep/.el-button--primary{
        background-color: #2878FF;
        border-color: #2878FF;
      }
      > .el-button {
        width: 100%;
        height: 2.5rem;
        font-size: 0.8rem;
      }
    }
    .loginTips {
      font-size: 0.7rem;
      text-align: left;
      line-height: 1.35rem;
      /deep/ .el-link {
        font-size: 12px;
        color: #2878FF;
      }
    }
  }
}
