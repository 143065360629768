#headPage {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.08);
}
#headPage .headCenter {
  width: 1440px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#headPage .headCenter /deep/ .el-tabs__nav-scroll,
#headPage .headCenter /deep/ .el-tabs__nav-wrap {
  overflow: unset;
}
#headPage .headCenter /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
  transition: 0.3s;
  position: relative;
  color: #2878ff;
}
#headPage .headCenter /deep/ .el-tabs--top .el-tabs__item.is-top:last-child:before {
  content: '';
  position: absolute;
  top: 59px;
  right: -13px;
  width: 60px;
  height: 3px;
  background: #fff;
  z-index: 1;
}
#headPage .headCenter /deep/ .el-tabs--top .el-tabs__item.is-top:last-child:after {
  content: '';
  position: absolute;
  top: 13px;
  right: -16px;
  width: 65px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #2878ff;
  background: transparent;
  transition: 0.3s;
  z-index: -1;
}
#headPage .headCenter /deep/ .el-tabs--top .el-tabs__item.is-top:last-child:hover {
  color: #fff;
}
#headPage .headCenter /deep/ .el-tabs--top .el-tabs__item.is-top:last-child:hover:after {
  background: #2878ff;
}
#headPage .headCenter .headLeft {
  display: flex;
  align-items: center;
}
#headPage .headCenter .headLeft span {
  font-size: 16px;
  font-weight: 400;
  color: #333;
}
#headPage .headCenter .headRight {
  height: 100%;
  padding-right: 18px;
}
#headPage .headCenter .headRight /deep/ .el-tabs {
  height: 100%;
}
#headPage .headCenter .headRight /deep/ .el-tabs__nav-wrap {
  margin: 0;
  height: 100%;
}
#headPage .headCenter .headRight /deep/ .el-tabs__nav-scroll {
  height: 100%;
}
#headPage .headCenter .headRight /deep/ .el-tabs__nav {
  height: 100%;
}
#headPage img {
  cursor: pointer;
}
@media (max-width: 1500px) {
  #headPage .headCenter {
    width: 1200px;
  }
}
.clearfix:after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.clearfix {
  zoom: 1;
}
 /deep/ .el-tabs__header {
  height: 100%;
  margin-bottom: 0;
}
 /deep/ .el-tabs__item {
  padding: 0 0 0 40px;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 62px;
}
 /deep/ .el-tabs__item:hover {
  color: #2878FF;
}
 /deep/ .el-tabs__nav-wrap::after {
  height: 0;
}
 /deep/ .el-tabs__item.is-active {
  color: #2878FF;
}
 /deep/ .el-tabs__active-bar {
  background-color: #2878FF;
  box-sizing: border-box;
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
  transition: unset;
}
.hideActiveBar /deep/ .el-tabs__active-bar {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
 /deep/ .el-dialog {
  border-radius: 8px;
}
 /deep/ .el-dialog__header {
  padding: 0 !important;
  height: 0;
}
 /deep/ .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: #666;
  transition: 0.3s;
}
 /deep/ .el-dialog__header .el-dialog__headerbtn .el-dialog__close:hover {
  color: #2878FF;
}
 /deep/ .el-dialog__header .el-icon-close:before {
  border: 0!important;
}
 /deep/ .el-dialog__header .el-dialog__headerbtn {
  left: 14px;
  right: unset;
  top: 14px;
  bottom: unset;
  transform: translateY(0);
}
 /deep/ .el-dialog__body {
  padding: 44px 40px 40px;
}
.loginBox /deep/ .el-tabs__header {
  margin-bottom: 20px;
}
.loginBox /deep/ .el-tabs__nav-wrap::after {
  height: 0;
}
.loginBox /deep/ .el-tabs__active-bar {
  border-left: 16px solid #fff;
  border-right: 16px solid #fff;
  background-color: #2878FF;
}
.loginBox /deep/ .el-form-item {
  margin-bottom: 30px!important;
}
.loginBox /deep/ .el-tabs__item {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}
.loginBox /deep/ .el-tabs__item.is-active {
  color: #2878FF;
}
.loginBox .loginTextButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginBox .loginTextButton /deep/ .el-button--text {
  padding: 0;
  color: #2878FF;
}
.loginBox .loginBottomButton {
  display: flex;
  margin-top: 15px;
}
.loginBox .loginBottomButton > .el-button {
  width: 100%;
  height: 40px;
  background: #2878ff;
  border: #2878ff;
  color: #fff;
}
.loginControl /deep/ .el-button--text {
  color: #2878FF;
}
.loginControl .login_box .autoLogin {
  border-bottom: none;
  margin-bottom: 15px!important;
  line-height: unset;
}
.loginControl .login_box .autoLogin /deep/ .el-form-item__content {
  line-height: unset;
}
.loginControl .login_box .autoLogin .forget {
  width: 70px;
  font-size: 14px;
  font-weight: 400;
  color: #2878FF;
  cursor: pointer;
  display: block;
  flex-grow: 1;
  text-align: start;
}
.loginControl .login_box .loginputHasbtnBox {
  position: relative;
}
.loginControl .login_box .loginputHasbtnBox /deep/ .el-button--text {
  position: absolute;
  top: 0;
  right: 14px;
}
.loginControl .login_box .loginputHasbtnBox .yzmNum {
  position: absolute;
  top: 0;
  right: 38px;
}
.loginControl .login_box .loginputHasbtn /deep/ .el-input__inner {
  padding-right: 100px;
}
.loginControl .login_box .loginputHasbtn /deep/ .el-input__icon {
  position: relative;
  right: 98px;
}
.loginControl .login_box .switch {
  right: 0;
  top: 0;
  position: absolute;
  width: 78px;
  height: 78px;
  cursor: pointer;
}
.loginControl .login_box .loginButton {
  margin-bottom: 20px;
  text-decoration: none;
}
.loginControl .login_box .loginButton /deep/ .el-button--primary {
  background-color: #2878FF;
  border-color: #2878FF;
}
.loginControl .login_box .loginButton > .el-button {
  width: 100%;
  height: 2.5rem;
  font-size: 0.8rem;
}
.loginControl .login_box .loginTips {
  font-size: 0.7rem;
  text-align: left;
  line-height: 1.35rem;
}
.loginControl .login_box .loginTips /deep/ .el-link {
  font-size: 12px;
  color: #2878FF;
}
